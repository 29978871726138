import React from "react"
import Management from "../../components/about/Management"
import PageHeader from "../../components/common/PageHeader"
import Layout from "../../components/layout"
import { Tab } from "@headlessui/react"

import { useStaticQuery, graphql } from "gatsby"

import HeaderFeaturedImage from "../../components/common/HeaderFeaturedImage"
import SEO from "../../components/seo"

const tabs = [{ name: "Management", href: "#", current: true }]
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function AboutUsPage() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 27 }) {
        content
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const content = data.wpPage.content
  const { title, featuredImage } = data.wpPage
  const img = data.wpPage.featuredImage.node.localFile.childImageSharp
  return (
    <Layout>
      <SEO title={title} />
      <div>
        {featuredImage && (
          <HeaderFeaturedImage featuredImage={featuredImage} title={title} />
        )}
        <div className="max-w-screen-2xl mx-auto py-8 lg:py-16 px-4 lg:px-8">
          <div className="flex flex-col gap-8">
            {!featuredImage ? <PageHeader text="About us" /> : null}
            {/* Introducton */}
            <div className="max-w-3xl  mx-auto flex flex-col xl:flex-row gap-4 lg:gap-8 items-center">
              <div className="flex-1">
                <div
                  dangerouslySetInnerHTML={{ __html: content }}
                  className="wp-content"
                />
              </div>
              {/* <div className="flex-1">
                <GatsbyImage image={getImage(img)} />
              </div> */}
            </div>
            <div className=" pg-4 lg:p-8">
              <div className="max-w-6xl  mx-auto flex flex-col justify-center items-center gap-8">
                <Tab.Group>
                  <Tab.List as="div" className="flex flex-row gap-4">
                    {tabs.map(tab => {
                      return (
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              " rounded-lg py-2.5 px-3 text-primary text-xl font-bold",
                              " focus:outline-none",
                              selected ? "bg-primary/10  " : ""
                            )
                          }
                        >
                          {tab.name}
                        </Tab>
                      )
                    })}
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel>
                      <Management />
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
          {/* Management */}
        </div>
      </div>
    </Layout>
  )
}
